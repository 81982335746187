import React from 'react';
import Card from './card.svg';
import Calendar from './calendar.svg';

export const listItem = [
    {
        heading: '6 months+ trading',
        description:
            'Your business needs to have been trading for at least the last 6 months.',
        imgsrc: Card
    },
    {
        heading: '£500+ monthly card sales',
        description:
            'You need to be generating at least £500 of monthly sales combined through card and online purchases.',
        imgsrc: Calendar
    }
];
