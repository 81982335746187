import React, { useState } from 'react';
import { graphql } from 'gatsby';
import MetaData from '../../components/Layout/MetaData.jsx';
import NavBar from '../../components/NavBar/NavBar';
import Faqs from './Faqs/Faqs';
import Footer from '../../components/Footer/Footer';
import FeatureBar from './FeatureBar/FeatureBar';
import Trustpilot from './Trustpilot/Trustpilot.jsx';
import InfoAndImage from './InfoAndImage/InfoAndImage';
import styles from './MCA.module.scss';
import {
    WedgedArch,
    ArchWithSquare
} from 'aphrodite-shared/SVGComponents/shapes';
import Eligibility from './components/FeaturedPoints/FeaturedPoints';
import { Hero } from './components/hero/hero';
import { COPY } from './MCA.constants';
import Questions from './components/Questions/Questions';
import { TextBlockHero } from './components/TextBlockHero/TextBlockHero.jsx';
import HeaderLink from './components/hero/HeaderLink.jsx';

const MCAPage = ({ data }) => {
    const { contentfulPageMcaProduct } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping,
        url,
        faqSection,
        infoSectionPrimaryImage,
        infoSectionTitle,
        infoSectionBlockText
    } = contentfulPageMcaProduct;
    const [isTablet, setIsTablet] = useState(false);

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', (e) => onWindowResize(e));
    }

    const onWindowResize = (e) => {
        setIsTablet(e.target.innerWidth <= 768);
    };

    return (
        <div className={styles.container}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar className={styles.navbar} />
            <main className={styles.main}>
                {isTablet ? (
                    <>
                        <Hero
                            displayCalculator={true}
                            COPY={COPY.HERO}
                            stringMatch={'mirrors'}
                        />
                        <WedgedArch
                            color={'core65'}
                            className={styles.wedgedArch}
                        />
                        <InfoAndImage
                            primaryImg={infoSectionPrimaryImage}
                            title={infoSectionTitle}
                            textBlock={
                                infoSectionBlockText.infoSectionBlockText
                            }
                        />
                        <Hero
                            COPY={COPY.NO_SALES_NO_WORRIES}
                            image={COPY.NO_SALES_NO_WORRIES.IMAGE}
                            stringMatch={'No worries.'}
                        />
                        <section>
                            <HeaderLink COPY={COPY.GET_CONNECTED} />
                            <TextBlockHero
                                COPY={COPY.GET_CONNECTED}
                                stringMatch="Open Banking"
                            />
                            <p className={styles.asteriskText}>
                                {COPY.GET_CONNECTED.IMAGE.CAPTION}
                            </p>
                        </section>
                        <Eligibility />
                        <Questions
                            img={COPY.ACCOUNT_MANAGER.IMAGE.SRC}
                            title={COPY.ACCOUNT_MANAGER.TITLE}
                            content={COPY.ACCOUNT_MANAGER.SUBTITLE}
                            email="contact@iwoca.co.uk"
                            phoneNumber="020 3778 0274"
                        />
                        {faqSection && <Faqs id={faqSection.id} />}
                        <Trustpilot />
                        <FeatureBar />
                    </>
                ) : (
                    <>
                        <Hero
                            displayCalculator={true}
                            COPY={COPY.HERO}
                            stringMatch={'mirrors'}
                        />
                        <WedgedArch
                            color={'core65'}
                            className={styles.wedgedArch}
                        />
                        <FeatureBar />
                        <InfoAndImage
                            primaryImg={infoSectionPrimaryImage}
                            title={infoSectionTitle}
                            textBlock={
                                infoSectionBlockText.infoSectionBlockText
                            }
                        />
                        <section className={styles.noSalesNoWorriesWrapper}>
                            <ArchWithSquare
                                className={styles.archWithSquare}
                                secondaryColor="denim20"
                                primaryColor="london80"
                            />
                            <Hero
                                COPY={COPY.NO_SALES_NO_WORRIES}
                                image={COPY.NO_SALES_NO_WORRIES.IMAGE}
                                stringMatch={'No worries.'}
                                colReverse={true}
                            />
                        </section>
                        <Hero
                            COPY={COPY.GET_CONNECTED}
                            image={COPY.GET_CONNECTED.IMAGE}
                            stringMatch={'Open Banking'}
                            rowReverse={true}
                            colReverse={true}
                        />
                        <Eligibility />
                        <Questions
                            img={COPY.ACCOUNT_MANAGER.IMAGE.SRC}
                            title={COPY.ACCOUNT_MANAGER.TITLE}
                            content={COPY.ACCOUNT_MANAGER.SUBTITLE}
                            email="contact@iwoca.co.uk"
                            phoneNumber="020 3778 0274"
                        />
                        {faqSection && <Faqs id={faqSection.id} />}
                        <Trustpilot />
                    </>
                )}
            </main>
            <Footer hideCta={true} />
        </div>
    );
};

export const query = graphql`
    query MCAPageQuery($id: String!) {
        contentfulPageMcaProduct(id: { eq: $id }) {
            name
            url
            titleTag
            metaDescription
            allowSearchEngineScraping
            faqSection {
                id
            }
            infoSectionPrimaryImage {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            infoSectionTitle
            infoSectionBlockText {
                infoSectionBlockText
            }
        }
    }
`;

export default MCAPage;
