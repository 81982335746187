import React from 'react';

const EmailIcon = ({ className }) => (
    <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 2.1001C0 0.767098 0.667 0.100098 2 0.100098H18C19.333 0.100098 20 0.767098 20 2.1001V14.1001C20 15.4331 19.333 16.1001 18 16.1001H2C0.667 16.1001 0 15.4331 0 14.1001V2.1001ZM2 2.1001V14.1001H18V2.1001H2ZM2 2.1001H3.414L10 8.6881L16.58 2.1001H18V3.5301L11.415 10.1061C10.943 10.4941 10.472 10.6881 10 10.6871C9.528 10.6871 9.057 10.4931 8.587 10.1041L2 3.5101V2.1001Z"
            fill="#08172B"
        />
    </svg>
);

export default EmailIcon;
