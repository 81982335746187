export const COPY = {
    BUSINESSES_APPROVED: {
        TITLE: '£3 billion+',
        SUBTITLE: 'approved small business loans'
    },
    APPROVED_BY_BUSINESSES: {
        TITLE: '50,000+',
        SUBTITLE: 'businesses approved'
    }
};
