import React from 'react';
import styles from './PressLogosStyles.module.scss';
import FinancialTimesLogo from './svg/FinancialTimesLogo';
import BBCLogo from './svg/BBCLogo';
import TelegraphLogo from './svg/TelegraphLogo';
import BusinessInsiderLogo from './svg/BusinessInsiderLogo';
import EveningStandardLogo from './svg/EveningStandardLogo';
import BloombergLogo from './svg/BloombergLogo';

const PressLogos = () => {
    return (
        <div className={styles.wrapper}>
            <span>
                <BBCLogo />
            </span>
            <span>
                <FinancialTimesLogo />
            </span>
            <span>
                <TelegraphLogo />
            </span>
            <span>
                <BusinessInsiderLogo />
            </span>
            <span>
                <BloombergLogo />
            </span>
            <span>
                <EveningStandardLogo />
            </span>
        </div>
    );
};

export default PressLogos;
