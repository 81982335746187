import React from 'react';
import DynamicTrustPilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import styles from './FeatureBar.module.scss';
import PressLogos from '../components/PressLogos/PressLogos';
import { COPY } from './FeatureBar.constants';

const FeatureBar = () => {
    return (
        <section className={styles.wrapper}>
            <div className={styles.features}>
                <div className={styles.USPs} key={`feature-${1}`}>
                    <div className={styles.USPBlurb}>
                        <h3>{COPY.BUSINESSES_APPROVED.TITLE}</h3>
                        <p>{COPY.BUSINESSES_APPROVED.SUBTITLE}</p>
                    </div>
                </div>
                <span className={styles.separator} />
                <DynamicTrustPilot className={styles.DesktopTrustpilot} />
                <span className={styles.separator} />
                <div className={styles.USPs} key={`feature-${2}`}>
                    <div className={styles.USPBlurb}>
                        <h3>{COPY.APPROVED_BY_BUSINESSES.TITLE}</h3>
                        <p>{COPY.APPROVED_BY_BUSINESSES.SUBTITLE}</p>
                    </div>
                </div>
            </div>
            <PressLogos />
        </section>
    );
};

export default FeatureBar;
