import React from 'react';
import FullStar from 'aphrodite-shared/ui/DynamicTrustpilot/FullStar';
import HalfStar from 'aphrodite-shared/ui/DynamicTrustpilot/HalfStar';
import styles from './TrustpilotItem.module.scss';

const TrustpilotItem = ({ review }) => {
    if (!review) {
        return null;
    }

    return (
        <div className={styles.item}>
            <div className={styles.flexContainer}>
                <div className={styles.topFlex}>
                    <div className={styles.nameRow}>
                        <div className={styles.initialsCircle}>
                            <span className={styles.initials}>
                                {review.initials}
                            </span>
                        </div>
                        <span className={styles.itemName}>{review.name}</span>
                    </div>
                    <h3 className={styles.itemTitle}>{review.title}</h3>
                    <p className={styles.itemText}>{review.text}</p>
                </div>
                <a
                    className={styles.itemStars}
                    tabindex="0"
                    href="https://uk.trustpilot.com/review/iwoca.co.uk"
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className={styles.StarsWrapper}>
                        <FullStar />
                        <FullStar />
                        <FullStar />
                        <FullStar />
                        {getFinalStar(review.stars)}
                    </div>
                </a>
            </div>
        </div>
    );
};

const getFinalStar = (score) => {
    if (score > 4.7) {
        return <FullStar />;
    } else {
        return <HalfStar />;
    }
};

export default TrustpilotItem;
