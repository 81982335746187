import React from 'react';

const FinancialTimesLogo = () => (
    <svg
        width="44"
        height="26"
        viewBox="0 0 44 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M40.369 0.21365H25.204C23.0681 0.21365 22.4273 0.213653 21.7865 6.10352e-05V7.68938H22.8545C23.4952 2.34957 24.5632 1.28161 29.2622 1.28161H30.5438V21.7865C30.5438 23.7088 29.6894 24.136 26.6991 24.136V25.2039H39.0875V24.136C36.0972 24.136 35.2428 23.9224 35.2428 21.7865V1.28161H36.5243C41.2234 1.28161 42.2913 2.13598 42.9321 7.68938H44.0001V6.10352e-05C43.1457 6.10352e-05 42.5049 0.21365 40.369 0.21365ZM6.10352e-05 0.21365V1.28161C2.34958 1.28161 3.20394 1.4952 3.20394 3.41753V22.2136C3.20394 24.136 2.34958 24.3496 6.10352e-05 24.3496V25.4175H11.7476V24.3496C8.33016 24.3496 7.903 23.9224 7.903 22.0001V13.0292H10.8933C14.0972 13.0292 14.3108 13.4564 14.5244 16.0195H15.3787V8.54375H14.5244C14.3108 11.1069 14.0972 11.7476 10.8933 11.7476H7.903V1.4952H11.7476C16.4467 1.4952 17.5146 2.34957 18.1554 7.90297H19.2234V0.21365C18.5826 0.427242 17.7282 0.427243 15.8059 0.427243H6.10352e-05V0.21365Z"
            fill="#437089"
        />
    </svg>
);

export default FinancialTimesLogo;
