import React, { useEffect } from 'react';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import TrustpilotItem from './TrustpilotItem/TrustpilotItem';
import { REVIEWS, COPY } from './Trustpilot.constants';
import styles from './Trustpilot.module.scss';
import { SecondaryCta } from '../components/PrimaryCta/SecondaryCta';

const Trustpilot = () => {
    useEffect(() => {
        const trustbox = document.getElementById('trustbox');
        if (window && window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustbox);
        }
    }, []);

    return (
        <section className={styles.container}>
            <h2 className={styles.title}>{COPY.SUBTITLE}</h2>
            <p className={styles.subTitle}>{COPY.TITLE}</p>
            <div className={styles.sliderContainer}>
                <div className={styles.slider}>
                    {REVIEWS.map((review) => (
                        <TrustpilotItem review={review} key={review.name} />
                    ))}
                </div>
            </div>
            <div className={styles.trustpilot}>
                <DynamicTrustpilot />
            </div>

            {/* <div
                id="trustbox"
                className="trustpilot-widget"
                data-locale="en-US"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="5098036f00006400051e2962"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-stars="4,5"
                data-review-languages="en">
                <a
                    href="https://www.trustpilot.com/review/iwoca.co.uk"
                    target="_blank"
                    rel="noopener">
                    Trustpilot
                </a>
            </div> */}
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <span className={styles.buttonContainerText}>
                        {COPY.BUTTON_CONTAINER}
                    </span>
                    <SecondaryCta href="#apply">{COPY.BUTTON}</SecondaryCta>
                </div>
            </div>
        </section>
    );
};

export default Trustpilot;
