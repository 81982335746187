import React from 'react';
import styles from './FeaturedPointsStyles.module.scss';
import { listItem } from './FeaturedPoints.constants';

const Eligibility = ({ list = listItem, title = 'Who is eligible' }) => {
    return (
        <section className={styles.wrapper}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.list}>
                {list.map((item, index) => {
                    return (
                        <>
                            <div className={styles.listItem}>
                                <div className={styles.imageContainer}>
                                    <img
                                        src={item.imgsrc}
                                        alt="eligibilty logos"
                                    />
                                </div>
                                <div className={styles.textBlock}>
                                    <h3 className={styles.heading}>
                                        {item.heading}
                                    </h3>
                                    <p className={styles.description}>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                            {index !== list.length - 1 && (
                                <hr className={styles.separator} />
                            )}
                        </>
                    );
                })}
            </div>
        </section>
    );
};

export default Eligibility;
