import React, { useEffect, useState } from 'react';
import { TextBlockHero } from '../TextBlockHero/TextBlockHero';
import styles from './heroStyles.module.scss';
import classname from 'classnames/bind';
import desktop from './svg/hero_desktop.svg';
import tablet from './svg/hero_tablet.svg';
import mobile from './svg/hero_mobile.svg';
import HeaderLink from './HeaderLink';
const cx = classname.bind(styles);

export const Hero = ({
    COPY,
    rowReverse = false,
    stringMatch,
    colReverse = false,
    image,
    displayCalculator
}) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const onWindowResize = () => {
            setIsDesktop(window.innerWidth > 1024);
            setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1024);
            setIsMobile(window.innerWidth < 600);
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', onWindowResize);
        }
        onWindowResize();

        return () => window.removeEventListener('resize', onWindowResize);
    }, []);

    return (
        <div
            className={cx(styles.wrapper, {
                wrapperReverse: rowReverse,
                wrapperColReverse: colReverse
            })}>
            <div className={styles.textBlock}>
                {COPY.SASH && <HeaderLink COPY={COPY} />}
                <TextBlockHero COPY={COPY} stringMatch={stringMatch} />
            </div>
            {isDesktop && !image && (
                <figure
                    className={cx(styles.graphContainer, {
                        graphContainerReverse: rowReverse
                    })}>
                    <img src={desktop} alt="Revenue based loan graph" />
                    {COPY.IMAGE.CAPTION && (
                        <figcaption>{COPY.IMAGE.CAPTION}</figcaption>
                    )}
                </figure>
            )}
            {isTablet && !image && (
                <figure
                    className={cx(styles.graphContainer, {
                        graphContainerReverse: rowReverse
                    })}>
                    <img src={tablet} alt="Revenue based loan graph" />
                    {COPY.IMAGE.CAPTION && (
                        <figcaption>{COPY.IMAGE.CAPTION}</figcaption>
                    )}
                </figure>
            )}
            {isMobile && !image && (
                <figure
                    className={cx(styles.graphContainer, {
                        graphContainerReverse: rowReverse
                    })}>
                    <img src={mobile} alt="Revenue based loan graph" />
                    {COPY.IMAGE.CAPTION && (
                        <figcaption>{COPY.IMAGE.CAPTION}</figcaption>
                    )}
                </figure>
            )}
            {image && (
                <figure
                    className={cx(styles.graphContainer, {
                        graphContainerReverse: rowReverse
                    })}>
                    <img src={COPY.IMAGE.SRC} alt="Revenue based loan graph" />
                    {COPY.IMAGE.CAPTION && (
                        <figcaption>{COPY.IMAGE.CAPTION}</figcaption>
                    )}
                </figure>
            )}
        </div>
    );
};
