import React from 'react';

const BloombergLogo = () => (
    <svg
        width="120"
        height="20"
        viewBox="0 0 120 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.43714 11.9634C9.44453 11.9634 10.326 11.4597 10.326 10.4523C10.326 9.31898 9.44453 8.94121 8.43714 8.94121H5.03719V12.0893L8.43714 11.9634ZM8.05934 6.04497C8.94081 6.04497 9.69637 5.6672 9.69637 4.65981C9.69637 3.77834 8.94081 3.40057 8.05934 3.40057H5.03719V6.04497H8.05934ZM0.126175 0.000640869H8.94082C10.5778 0.000640869 11.8371 0.378411 12.8445 1.00803C13.8519 1.76357 14.4815 2.77096 14.4815 4.03019C14.4815 5.54127 13.7259 6.42274 12.3407 7.05236V7.17828C14.2296 7.68197 15.237 9.19306 15.237 10.8301C15.237 12.4671 14.4815 13.7263 13.2222 14.6078C12.2148 15.2374 10.9556 15.4892 9.06676 15.4892H0.000244141V0.000640869H0.126175Z"
            fill="#437089"
        />
        <path
            d="M20.6502 6.10352e-05H15.9911V15.4887H20.6502V6.10352e-05Z"
            fill="#437089"
        />
        <path
            d="M30.2228 9.94873C30.2228 8.05988 29.4672 6.80064 28.0821 6.80064C26.6969 6.80064 26.0673 8.05988 26.0673 9.94873C26.0673 11.8376 26.6969 13.0968 28.0821 13.0968C29.4672 13.0968 30.2228 11.8376 30.2228 9.94873ZM21.5341 9.94873C21.5341 6.42287 24.1784 4.15625 28.208 4.15625C32.2375 4.15625 34.756 6.5488 34.756 9.94873C34.756 13.4746 32.2375 15.7412 28.208 15.7412C24.1784 15.7412 21.5341 13.4746 21.5341 9.94873Z"
            fill="#437089"
        />
        <path
            d="M43.9476 9.94873C43.9476 8.05988 43.1921 6.80064 41.807 6.80064C40.4218 6.80064 39.7922 8.05988 39.7922 9.94873C39.7922 11.8376 40.4218 13.0968 41.807 13.0968C43.318 13.0968 43.9476 11.8376 43.9476 9.94873ZM35.2589 9.94873C35.2589 6.42287 37.9033 4.15625 41.9328 4.15625C45.9624 4.15625 48.4809 6.42287 48.4809 9.94873C48.4809 13.4746 45.9624 15.7412 41.9328 15.7412C37.9033 15.7412 35.2589 13.4746 35.2589 9.94873Z"
            fill="#437089"
        />
        <path
            d="M49.4929 4.41177H53.7744V6.04878H53.9002C54.7817 4.78954 55.915 4.034 57.8039 4.034C59.4409 4.034 60.7001 4.66361 61.2038 5.92285H61.3298C62.3372 4.53769 63.7223 4.034 65.2334 4.034C68.2555 4.034 69.5148 5.92285 69.5148 8.44132V15.3671H65.1075V9.19687C65.1075 8.06355 64.7297 7.30801 63.5964 7.30801C62.4631 7.30801 61.9594 8.18948 61.9594 9.44871V15.3671H57.3002V9.19687C57.3002 8.06355 56.9224 7.30801 55.7891 7.30801C54.6558 7.30801 54.1521 8.18948 54.1521 9.44871V15.3671H49.4929V4.41177Z"
            fill="#437089"
        />
        <path
            d="M79.3349 9.8221C79.3349 8.05917 78.5793 6.92586 77.1942 6.92586C75.809 6.92586 74.9276 8.05917 74.9276 9.8221C74.9276 11.585 75.809 12.7183 77.1942 12.7183C78.5793 12.7183 79.3349 11.585 79.3349 9.8221ZM75.0535 13.9776V15.3627H70.7721V6.10352e-05H75.1794V5.54069H75.3053C76.1868 4.53331 77.3201 4.02961 78.9571 4.02961C82.1052 4.02961 83.9941 6.54809 83.9941 9.8221C83.9941 13.5998 81.9793 15.7405 78.7053 15.7405C77.1942 15.7405 75.809 15.2368 75.0535 13.9776Z"
            fill="#437089"
        />
        <path
            d="M88.9043 8.6895H93.0597C92.9338 7.30434 92.1783 6.67472 91.045 6.67472C89.7857 6.67472 89.0302 7.43026 88.9043 8.6895ZM84.4969 9.94873C84.4969 6.5488 87.1414 4.15625 90.9191 4.15625C92.8079 4.15625 94.319 4.65994 95.3264 5.54141C96.8375 6.80064 97.4671 8.6895 97.4671 10.9561H88.9043C89.0302 12.3413 89.7857 13.0968 91.1709 13.0968C91.9264 13.0968 92.682 12.7191 92.9338 12.0894H97.2152C96.4597 14.6079 94.193 15.8671 91.045 15.8671C87.1413 15.7412 84.4969 13.3487 84.4969 9.94873Z"
            fill="#437089"
        />
        <path
            d="M102.634 6.29695C103.642 4.78587 104.901 4.15625 106.412 4.15625C106.79 4.15625 107.042 4.15625 107.167 4.28217V7.68211H107.042C104.397 7.30434 102.886 8.31173 102.886 10.8302V15.4894H98.3528V4.4081H102.634V6.29695Z"
            fill="#437089"
        />
        <path
            d="M115.845 9.44504C115.845 8.05988 115.09 6.92657 113.83 6.92657C112.571 6.92657 111.816 7.93396 111.816 9.44504C111.816 10.9561 112.571 11.9635 113.83 11.9635C115.09 12.0894 115.845 10.9561 115.845 9.44504ZM107.534 15.4894H111.816C111.942 15.9931 112.571 16.3708 113.579 16.3708C114.964 16.3708 115.593 15.6153 115.593 14.482V13.7264H115.468C114.712 14.482 113.83 14.9857 112.445 14.9857C109.675 14.9857 107.282 13.2227 107.282 9.82281C107.282 6.67472 109.297 4.15625 112.193 4.15625C113.956 4.15625 114.964 4.78587 115.593 5.66733H115.719V4.4081H120.001V13.9783C120.001 15.7412 119.245 17.1264 118.238 17.8819C117.104 18.7634 115.593 19.1411 113.705 19.1411C110.305 19.1411 107.912 17.8819 107.534 15.4894Z"
            fill="#437089"
        />
    </svg>
);

export default BloombergLogo;
