import React from 'react';
import insured from './svg/insured.svg';
import open_link from './svg/open_link.svg';
import styles from './heroStyles.module.scss';

const HeaderLink = ({ COPY }) => {
    return (
        <div className={styles.sash}>
            <figure>
                <img src={insured} alt="insured icon" />
            </figure>
            <span>
                {COPY.SASH.LABEL}{' '}
                <a href={COPY.SASH.LINK.TARGET}>
                    {COPY.SASH.LINK.LABEL}{' '}
                    <img src={open_link} alt="anchor link icon" />
                </a>
            </span>
        </div>
    );
};

export default HeaderLink;
