import React from 'react';
import Button from 'aphrodite-shared/ui/Button/Button';
import styles from './CtaStyles.module.scss';

export const SecondaryCta = ({ label, children, ...props }) => {
    return (
        <Button role={'button'} className={styles.SecondaryButton} {...props}>
            {label ? label : children}
        </Button>
    );
};
