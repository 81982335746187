import React from 'react';
import styles from './TextBlockHeroStyles.module.scss';
import { PrimaryCta } from '../PrimaryCta/PrimaryCta.jsx';

const renderColourChange = (text, match) => {
    const reg = new RegExp(`(${match})`, 'gi');
    const textParts = text.split(reg);
    return (
        <h2 className={styles.title}>
            {textParts.map((part) =>
                part.match(reg) ? (
                    <>
                        {match === 'No worries.' && <br />}
                        <span className={styles.colouredString}>{part}</span>
                    </>
                ) : (
                    part
                )
            )}
        </h2>
    );
};

export const TextBlockHero = ({ COPY, stringMatch, subtitleSemiBold }) => {
    return (
        <>
            {stringMatch === undefined ? (
                <h2 className={styles.title}>{COPY.TITLE}</h2>
            ) : (
                renderColourChange(COPY.TITLE, stringMatch)
            )}
            <p className={styles.subtitle}>{COPY.SUBTITLE}</p>
            {COPY.LIST && (
                <ul className={styles.list}>
                    {COPY.LIST.map((item) => (
                        <li>
                            <span>{item}</span>
                        </li>
                    ))}
                </ul>
            )}
            {COPY.CTA && (
                <div className={styles.ctaContainer}>
                    <PrimaryCta label={COPY.CTA.LABEL} href="#apply" />
                    <p>{COPY.CTA.SUBTEXT}</p>
                </div>
            )}
        </>
    );
};
