import React from 'react';

const EmailIcon = () => (
    <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.678 11.4681C14.313 11.1781 14.942 11.2831 14.942 11.2831C16.528 11.5841 17.682 11.8061 18.405 11.9491C18.405 11.9491 19.132 12.0451 19.604 12.6861C20.075 13.3261 20.015 13.9111 20.015 13.9111V18.1001C20.015 19.4361 19.37 20.1021 18.079 20.0991C17.9207 20.1198 17.7615 20.1328 17.602 20.1381C12.397 20.1381 8.23299 18.6101 5.20799 15.5851C1.48499 11.8621 -0.242011 7.3491 0.0279892 2.0471C0.0179892 0.749098 0.683989 0.100098 2.02499 0.100098C3.06199 0.100098 4.46999 0.100098 6.24799 0.102098C6.24799 0.102098 6.96199 0.0580976 7.54699 0.578098C8.13199 1.0981 8.21099 1.7321 8.21099 1.7321C8.34799 2.4551 8.55399 3.6171 8.83099 5.2171C8.83099 5.2171 8.96499 5.8721 8.64099 6.4581C8.42665 6.82668 8.12033 7.13335 7.75199 7.3481L7.17799 7.6371C7.59899 9.0231 8.90799 12.0381 12.469 12.9631L12.786 12.3401C12.786 12.3401 13.042 11.7591 13.676 11.4681H13.678ZM18.026 18.1341V13.9191L14.576 13.2301L14.209 13.9571C14.109 14.1551 13.996 14.4781 13.46 14.7381C12.925 14.9981 12.235 14.9161 12.235 14.9161C11.8907 14.8694 11.5522 14.787 11.225 14.6701C7.51199 13.3631 5.74099 10.0751 5.17499 7.9261C5.05944 7.53745 5.09112 7.11986 5.26399 6.7531C5.50399 6.2441 5.93099 6.0251 6.11099 5.9341L6.88799 5.5461L6.20499 2.1001H2.02799C2.10099 4.3761 2.68999 10.2471 6.63099 14.1801C9.34399 16.8881 13.171 18.2331 18.026 18.1341Z"
            fill="#08172B"
        />
    </svg>
);

export default EmailIcon;
