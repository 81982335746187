import React from 'react';

const BBCLogo = () => (
    <svg
        width="96"
        height="28"
        viewBox="0 0 96 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33.4924 6.10352e-05H62.4935V27.6523H33.4924V6.10352e-05ZM51.2528 13.0393C51.2528 13.0393 53.7258 11.9152 53.7258 8.99265C53.7258 8.99265 54.1754 4.27154 47.8806 3.5971H40.9114V23.3808H48.7799C48.7799 23.3808 55.2995 23.3808 55.2995 17.7604C55.5243 17.9852 55.7491 14.1634 51.2528 13.0393ZM44.5083 6.96932H47.431C50.3536 7.19413 50.3535 9.21747 50.3535 9.21747C50.3535 11.9152 47.2061 11.9152 47.2061 11.9152H44.7332V6.96932H44.5083ZM51.9273 17.7604C51.9273 20.683 48.3302 20.683 48.3302 20.683H44.7332V15.2875H48.3302C52.1521 15.2875 51.9273 17.7604 51.9273 17.7604Z"
            fill="#437089"
        />
        <path
            d="M0 0.000717163H29.0011V27.6529H0V0.000717163ZM17.9852 13.04C17.9852 13.04 20.4582 11.9159 20.4582 8.99331C20.4582 8.99331 20.9078 4.2722 14.613 3.59775H7.64369V23.3815H15.5122C15.5122 23.3815 22.0318 23.3815 22.0318 17.7611C22.2566 17.9859 22.2567 14.164 17.9852 13.04ZM11.2407 6.96997H14.1633C17.0859 7.19479 17.0859 9.21812 17.0859 9.21812C17.0859 11.9159 13.9385 11.9159 13.9385 11.9159H11.2407V6.96997ZM18.6596 17.7611C18.6596 20.6837 15.0626 20.6837 15.0626 20.6837H11.2407V15.2881H14.8378C18.6596 15.2881 18.6596 17.7611 18.6596 17.7611Z"
            fill="#437089"
        />
        <path
            d="M66.9988 27.6523H96V6.10352e-05H66.9988V27.6523ZM89.93 5.1708V8.99265C89.93 8.99265 86.333 6.7445 82.5111 6.7445C82.5111 6.7445 75.317 6.51968 74.8674 13.7138C74.8674 13.7138 74.6426 20.4582 82.5111 20.683C82.5111 20.683 85.8833 21.1326 90.1548 18.2101V22.0319C90.1548 22.0319 84.3096 25.6289 77.3403 22.9312C77.3403 22.9312 71.4952 20.683 71.2704 13.7138C71.2704 13.7138 71.0455 6.51969 78.6892 4.04672C78.6892 4.04672 80.7126 3.14747 84.5344 3.5971C84.5344 3.5971 86.7826 3.82191 89.93 5.1708Z"
            fill="#437089"
        />
    </svg>
);

export default BBCLogo;
