import React from 'react';

const Quote = ({ className, width, height }) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 32 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.62793 14.7405C0.62793 21.2205 3.50793 25.2525 8.33193 25.2525C12.0759 25.2525 14.6679 22.9485 14.6679 19.4925C14.6679 16.1085 12.0759 14.0205 7.97193 14.0205V13.0845C7.97193 10.8525 9.19593 9.12445 12.0759 7.18046C13.3719 6.38845 13.6599 5.66846 13.2279 4.51646L12.1479 1.78046C11.7159 0.700456 10.7799 0.268457 9.69993 0.700455C4.15593 3.07645 0.62793 8.33246 0.62793 14.2365V14.7405ZM17.3319 14.7405C17.3319 21.2205 20.2119 25.2525 25.0359 25.2525C28.7799 25.2525 31.3719 22.9485 31.3719 19.4925C31.3719 16.1085 28.7799 14.0205 24.6759 14.0205V13.0845C24.6759 10.8525 25.8999 9.12445 28.7799 7.18046C30.0759 6.38845 30.3639 5.66846 29.9319 4.51646L28.8519 1.78046C28.4199 0.700456 27.4839 0.268457 26.4039 0.700455C20.8599 3.07645 17.3319 8.33246 17.3319 14.2365V14.7405Z"
                fill="#4B7D9B"
            />
        </svg>
    );
};

export default Quote;
