import React from 'react';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import WantToKnowMore from '../../uk/common/WantToKnowMore/WantToKnowMore';
import styles from './Faqs.module.scss';

function useFaqs(id) {
    const { allContentfulSectionFaQs: data } = useStaticQuery(
        graphql`
            {
                allContentfulSectionFaQs {
                    nodes {
                        id
                        title
                        subtitle {
                            subtitle
                        }
                        faqs {
                            question
                            answer
                            longAnswer {
                                longAnswer
                            }
                            color
                        }
                        icon {
                            id
                            file {
                                url
                            }
                        }
                    }
                }
            }
        `
    );

    return data.nodes.find((item) => item.id === id);
}

const Faqs = ({ id, className }) => {
    const faqSection = useFaqs(id);

    return (
        <div className={styles.container}>
            <div className={styles.icon} role="img" />
            <WantToKnowMore
                faq={faqSection.faqs}
                title={faqSection.title}
                subtitle={faqSection.subtitle.subtitle}
                className={styles.faqs}
            />
        </div>
    );
};

export default Faqs;
