export const REVIEWS = [
    {
        name: 'Ibrahim Mayat',
        initials: 'IM',
        title: 'Revenue Based Loan',
        text: `Iwoca.  Great facility for businesses.  Revenue based loan.  helped us grow our business.  Now using the facility for a second time
            Customer service is no 1.  always someone available to answer youre call within a few rings.  Online portal very easy to use and montor your payments and balance etc.`,
        stars: 4.7
    },
    {
        name: 'Business owner',
        initials: 'BO',
        title: '1st class rapid service',
        text:
            'Really impressed with the service received, from my first communication to the money in the bank was no more than 3 hours!! Yusef was a pleasure to deal with and I will definitely be using them again in the future….. 1 very happy customer!!',
        stars: 5
    },
    {
        name: 'Cateritaly Ltd',
        initials: 'CL',
        title: 'Unbelievably fast and friendly',
        text: 'Unbelievably fast and friendly.  I recommend them.',
        stars: 4.7
    }
];

export const COPY = {
    SUBTITLE: 'See our reviews on Trustpilot',
    TITLE: `Don't just take our word for it`,
    BUTTON_CONTAINER: 'Looking to get your own Revenue Based Loan sorted?',
    BUTTON: 'Get started'
};
