import get_connected from './components/hero/svg/get_connected.png';
import no_sales_no_worries_graph from './components/hero/svg/iwoca-no-sales-revenue-based-loan-graph.svg';
import hero_desktop from './components/hero/svg/hero_desktop.svg';
import lisa from './components/hero/svg/iwoca-account-manager-lisa.png';

export const COPY = {
    HERO: {
        TITLE: 'A business loan that mirrors your turnover',
        SUBTITLE:
            'The loan where repayments are a percentage of your monthly sales with no interest to pay, just a fixed fee.',
        IMAGE: {
            SRC: hero_desktop
        },
        CTA: {
            LABEL: 'Get started',
            SUBTEXT: 'Don’t worry, it won’t affect your credit score'
        }
    },
    GET_CONNECTED: {
        TITLE: 'Repay based on future sales with Open Banking',
        SUBTITLE:
            'To apply for the Revenue Based Loan, you will need to share your bank data through Open Banking* - which is safe, secure & straightforward.',
        LIST: [
            'No need to compile statements',
            'No need to share your password'
        ],
        CTA: {
            LABEL: 'Get started',
            SUBTEXT: 'Don’t worry, it won’t affect your credit score'
        },
        SASH: {
            LINK: {
                TARGET: 'https://www.openbanking.org.uk/',
                LABEL: 'Open Banking'
            },
            LABEL: '100% regulated'
        },
        IMAGE: {
            SRC: get_connected,
            CAPTION:
                '*Only available with Natwest, Santander, Lloyds Bank, Barclays and HSBC business accounts at this time.'
        }
    },
    NO_SALES_NO_WORRIES: {
        TITLE: `Low sales? No worries.`,
        SUBTITLE: `A quiet month, seasonal shift? If sales slow down, so will your repayments. Because this loan breathes with your business, you'll make up any lost ground as you get back on track*.`,
        CTA: {
            LABEL: 'Get Started',
            SUBTEXT:
                '*Minimum performance requirements to be met every two months '
        },
        IMAGE: {
            SRC: no_sales_no_worries_graph
        }
    },
    ACCOUNT_MANAGER: {
        TITLE: 'We’re here to help',
        SUBTITLE:
            "Small business loans are all we do, so you'll get straight through to someone who can help, like Lisa – we're not fans of hold music. Call us from Monday to Friday (9 am - 6 pm). We accept applications over the phone.",
        CTA: {
            LABEL: 'Start your journey',
            SUBTEXT: 'Don’t worry, it won’t affect your credit score'
        },
        IMAGE: {
            SRC: lisa
        }
    }
};
