import React from 'react';
import styles from './Questions.module.scss';
import ReactMarkdown from 'react-markdown/with-html';
import TelIcon from './svg/TelIcon';
import EmailIcon from './svg/EmailIcon';
import { formatPhoneNumber } from '../../../../util/localization';
import { PrimaryCta } from '../PrimaryCta/PrimaryCta';

const Questions = ({ title, content, email, phoneNumber, img }) => {
    return (
        <section>
            <div className={styles.Questions}>
                <div className={styles.imageContainer}>
                    <img src={img} alt="Customer support" />
                </div>
                <div className={styles.content}>
                    <h2>Questions?</h2>
                    <h3>{title}</h3>
                    <ReactMarkdown source={content} escapeHtml={false} />
                    <div className={styles.ctaContainer}>
                        <PrimaryCta
                            label={'Start your journey'}
                            href="#apply"
                        />
                    </div>
                    <div className={styles.contact}>
                        <div className={styles.tel}>
                            <TelIcon />
                            <a
                                href={formatPhoneNumber(phoneNumber)}
                                data-ga-id="questions__phone">
                                {phoneNumber}
                            </a>
                        </div>
                        <div className={styles.email}>
                            <EmailIcon />
                            <a
                                href={`mailto:${email}`}
                                data-ga-id="questions__email">
                                {email}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Questions;
