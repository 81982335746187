import React from 'react';

const EveningStandardLogo = () => (
    <svg
        width="132"
        height="17"
        viewBox="0 0 132 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.90588 12.3401H6.33985C5.43419 12.3401 5.20778 12.0004 5.20778 11.4344V7.01933H6.0002C7.24548 7.01933 7.69831 7.58537 8.26435 9.05706H8.49077V4.52878H8.26435C7.5851 6.00047 7.24548 6.56651 6.0002 6.56651H5.20778V1.01936H7.13227C8.26435 1.01936 8.71719 1.92501 9.73606 3.62312H10.0757V0.453323H0.000244141V0.792944C1.13231 0.906151 1.47192 1.47219 1.47192 2.15143V11.0948C1.47192 11.774 1.24552 12.4533 0.000244141 12.4533V12.7929H10.3021L10.6417 9.17027H10.3021C9.28323 11.0948 8.71719 12.3401 6.90588 12.3401Z"
            fill="#437089"
        />
        <path
            d="M40.4105 11.5503V3.96539H36.4482V4.19181C37.0143 4.30502 37.2407 4.64464 37.2407 5.21067V11.5503C37.2407 12.1163 37.0143 12.5691 36.4482 12.5691V12.7955H41.3161V12.5691C40.6369 12.5691 40.4105 12.1163 40.4105 11.5503Z"
            fill="#437089"
        />
        <path
            d="M46.6389 12.5587V12.7852H51.2803V12.5587C50.7143 12.4455 50.6011 12.1059 50.6011 11.5399V6.33235C50.6011 4.29463 49.3558 3.8418 48.2238 3.8418C46.8653 3.8418 45.8464 4.74745 45.2804 5.76632L45.3936 4.06821H41.3181V4.29463C41.8842 4.40783 42.1106 4.63425 42.1106 5.31349V11.6531C42.1106 12.4455 41.771 12.6719 41.4313 12.6719V12.8984H46.186V12.6719C45.62 12.5587 45.2804 12.3323 45.2804 11.6531V5.99273C45.5068 5.87953 45.9596 5.65311 46.2992 5.65311C46.8653 5.65311 47.3181 5.87952 47.3181 6.67197V11.5399C47.3181 12.2191 47.0917 12.5587 46.6389 12.5587Z"
            fill="#437089"
        />
        <path
            d="M19.0182 4.19181V3.96539H16.1881V4.19181C16.7541 4.30502 17.0937 4.41822 17.0937 4.98426C17.0937 5.32388 16.9805 5.6635 16.8673 6.11633L15.5089 10.9842L13.8108 5.6635C13.5843 4.87105 13.924 4.41822 14.8296 4.30502V4.0786H9.28247V4.30502C9.62209 4.41822 9.9617 4.75784 10.1881 5.55029L12.6787 12.9088H15.2824L17.5466 6.11633C18.2258 4.64464 18.5654 4.41822 19.0182 4.19181Z"
            fill="#437089"
        />
        <path
            d="M35.5476 11.5399V6.33235C35.5476 4.29463 34.3023 3.8418 33.1703 3.8418C31.8118 3.8418 30.7929 4.74745 30.2269 5.76632L30.3401 4.06821H26.2646V4.29463C26.8307 4.40783 27.0571 4.63425 27.0571 5.31349V11.6531C27.0571 12.4455 26.6043 12.6719 26.2646 12.6719V12.8984H31.1325V12.6719C30.5665 12.5587 30.3401 12.3323 30.3401 11.6531V5.99273C30.5665 5.87953 31.0193 5.65311 31.359 5.65311C31.925 5.65311 32.3778 5.87952 32.3778 6.67197V11.5399C32.3778 12.2191 32.1514 12.4455 31.6986 12.5587V12.7852H36.3401V12.5587C35.6608 12.4455 35.5476 12.1059 35.5476 11.5399Z"
            fill="#437089"
        />
        <path
            d="M22.414 4.29463C22.8669 4.29463 23.2065 4.63424 23.2065 5.65311V7.35121H21.282C21.282 5.08707 21.7348 4.29463 22.414 4.29463ZM23.5461 11.087C22.3008 11.087 21.1688 10.1814 21.1688 7.91725H26.1499V7.57763C26.1499 5.08707 24.565 3.8418 22.414 3.8418C19.4707 3.8418 17.7726 5.99273 17.7726 8.82291C17.7726 11.087 19.2443 13.0116 21.9612 13.0116C24.3386 13.0116 25.6971 11.6531 26.1499 9.95498L25.9235 9.84177C25.3574 10.7474 24.4518 11.087 23.5461 11.087Z"
            fill="#437089"
        />
        <path
            d="M38.7191 3.39627C39.6248 3.39627 40.4172 2.60382 40.4172 1.69817C40.4172 0.79251 39.6248 6.10352e-05 38.7191 6.10352e-05C37.8134 6.10352e-05 37.021 0.79251 37.021 1.69817C37.021 2.71703 37.8134 3.39627 38.7191 3.39627Z"
            fill="#437089"
        />
        <path
            d="M56.3728 6.67989V6.90631C56.3728 8.60441 56.1464 9.28365 55.5804 9.28365C55.0144 9.28365 54.6747 8.49121 54.6747 6.90631V6.67989C54.6747 4.98179 54.9012 4.30255 55.5804 4.30255C56.1464 4.30255 56.3728 4.98179 56.3728 6.67989ZM57.5049 4.18934C56.9389 3.96292 56.2596 3.84972 55.5804 3.84972C53.3163 3.84972 51.5049 4.98179 51.5049 6.7931C51.5049 8.03838 52.1842 8.83083 53.203 9.28365C52.071 9.62327 51.5049 10.5289 51.5049 11.3214C51.5049 12.3402 52.1842 13.2459 54.1087 13.3591L56.7125 13.4723C57.9577 13.4723 58.5238 13.8119 58.5238 14.4912C58.5238 15.2836 57.3917 16.0761 55.4672 16.0761C53.5427 16.0761 52.8634 15.2836 52.8634 14.6044C52.8634 14.2648 53.0898 13.8119 53.3163 13.5855C53.0898 13.5855 52.9766 13.5855 52.7502 13.5855C51.5049 13.5855 50.8257 14.1516 50.8257 14.8308C50.8257 15.8497 51.8445 16.6421 54.7879 16.6421C57.0521 16.6421 60.2219 15.51 60.2219 12.9063C60.2219 11.8874 59.5426 10.6421 57.2785 10.6421H53.7691C53.0898 10.6421 52.9766 10.4157 52.9766 10.0761C52.9766 9.84969 53.0898 9.62328 53.203 9.51007C53.8823 9.73648 54.5615 9.84969 55.354 9.84969C57.6181 9.84969 59.4294 8.71762 59.4294 6.7931C59.4294 5.77423 58.8634 4.98179 57.9577 4.41575C58.8634 4.75537 60.1087 5.09499 60.5615 5.2082H60.6747V3.73651C59.8823 4.07613 59.0898 4.30255 58.1841 4.30255C57.9577 4.18934 57.7313 4.18934 57.5049 4.18934Z"
            fill="#437089"
        />
        <path
            d="M110.149 11.5399C110.036 11.5399 109.923 11.5399 109.809 11.5399C109.13 11.5399 108.564 10.9738 108.564 10.1814V10.0682C108.564 8.48329 109.923 8.25687 110.149 7.35121V11.5399ZM114.677 11.8795L114.564 11.7663C114.338 11.8795 114.111 11.9927 113.998 11.9927C113.659 11.9927 113.432 11.6531 113.432 11.3135C113.432 8.25687 113.432 6.21914 113.432 6.21914C113.432 4.74745 112.074 3.8418 109.923 3.8418C107.659 3.8418 105.734 4.74745 105.734 6.10594C105.734 6.89839 106.413 7.57763 107.206 7.57763C108.111 7.57763 108.791 6.89839 108.791 6.10594C108.791 5.31349 108.111 4.63425 107.319 4.63425C107.545 4.52104 107.885 4.52104 108.111 4.52104C109.243 4.52104 110.149 5.20028 110.149 6.33235V6.44556C110.149 7.46442 109.583 7.91725 108.225 8.37008C106.526 8.82291 105.394 9.38894 105.394 10.9738C105.394 12.3323 106.413 13.238 107.772 13.238C108.904 13.238 109.583 12.6719 110.036 11.9927C110.149 12.7852 110.715 13.238 111.847 13.238C113.206 13.0116 114.111 12.5587 114.677 11.8795Z"
            fill="#437089"
        />
        <path
            d="M101.432 10.642C101.432 11.4344 100.753 11.774 100.3 11.774C99.3945 11.774 98.9416 11.208 98.9416 10.1891V5.88726C98.9416 4.9816 99.6209 4.52878 100.3 4.52878C100.866 4.52878 101.432 4.98161 101.432 5.77406V10.642ZM104.602 11.5476V0.453323H100.413V0.679738C101.319 0.792945 101.432 0.792945 101.432 1.24577V4.41557C101.093 4.07595 100.413 3.73633 99.5077 3.73633C97.5831 3.73633 95.5454 5.20802 95.5454 8.49102C95.5454 11.5476 96.9039 12.9061 98.8284 12.9061C100.3 12.9061 101.093 12.0004 101.432 11.4344V12.6797H105.508V12.4533C104.715 12.4533 104.602 12.1136 104.602 11.5476Z"
            fill="#437089"
        />
        <path
            d="M120.337 3.8418C119.432 3.8418 118.639 4.63425 118.639 5.5399C118.639 6.44556 119.432 7.23801 120.337 7.23801C121.243 7.23801 122.035 6.44556 122.035 5.5399C122.149 4.52104 121.356 3.8418 120.337 3.8418Z"
            fill="#437089"
        />
        <path
            d="M127.812 10.642C127.812 11.4344 127.132 11.774 126.68 11.774C125.774 11.774 125.321 11.208 125.321 10.1891V5.88726C125.321 4.9816 126 4.52878 126.68 4.52878C127.246 4.52878 127.812 4.98161 127.812 5.77406V10.642ZM131.095 11.5476V0.453323H126.906V0.679738C127.812 0.792945 127.925 0.792945 127.925 1.24577V4.41557C127.585 4.07595 126.906 3.73633 126 3.73633C124.076 3.73633 122.038 5.20802 122.038 8.49102C122.038 11.5476 123.397 12.9061 125.321 12.9061C126.793 12.9061 127.585 12.0004 127.925 11.4344V12.6797H132V12.4533C131.208 12.4533 131.095 12.1136 131.095 11.5476Z"
            fill="#437089"
        />
        <path
            d="M118.303 11.3204V6.56569C118.303 5.43362 118.303 4.86758 118.416 4.07513H114.341V4.30155C114.907 4.41476 115.134 4.75437 115.134 5.32041V11.66C115.134 12.4525 114.681 12.6789 114.454 12.6789V12.9053H119.435V12.6789C118.643 12.4525 118.303 12.1128 118.303 11.3204Z"
            fill="#437089"
        />
        <path
            d="M94.7549 11.5399V6.33235C94.7549 4.29463 93.5096 3.8418 92.3776 3.8418C91.0191 3.8418 90.0002 4.74745 89.4342 5.76632L89.5474 4.06821H85.4719V4.29463C86.038 4.40783 86.2644 4.63425 86.2644 5.31349V11.6531C86.2644 12.4455 85.9248 12.6719 85.5851 12.6719V12.8984H90.3398V12.6719C89.7738 12.5587 89.4342 12.3323 89.4342 11.6531V5.99273C89.6606 5.87953 90.1134 5.65311 90.453 5.65311C91.0191 5.65311 91.4719 5.87952 91.4719 6.67197V11.5399C91.4719 12.2191 91.2455 12.4455 90.7927 12.5587V12.7852H95.4341V12.5587C94.8681 12.4455 94.7549 12.1059 94.7549 11.5399Z"
            fill="#437089"
        />
        <path
            d="M76.4135 11.2089C76.3003 11.3221 75.9606 11.5485 75.621 11.5485C75.055 11.5485 74.8286 11.2089 74.8286 10.6429V4.6429H76.6399V4.07686H74.7154V2.15234H74.4889C73.5833 3.17121 71.8852 4.07686 70.6399 4.30328V4.52969H71.4324V10.8693C71.4324 11.8881 71.8852 12.907 73.5833 12.907C75.1682 12.907 76.1871 12.0014 76.5267 11.2089H76.4135Z"
            fill="#437089"
        />
        <path
            d="M68.4944 5.77274L66.5699 4.41426C65.8906 3.96143 65.3246 3.28219 65.3246 2.60295C65.3246 1.58408 66.117 0.904841 67.1359 0.904841C68.4944 0.904841 69.0604 1.9237 69.9661 3.84822H70.3057L70.4189 0.904841C69.6265 0.56522 68.3812 0.338806 67.1359 0.338806C64.1925 0.338806 62.1548 1.9237 62.1548 4.18784C62.1548 5.54633 62.9472 6.45199 63.9661 7.13123L65.8906 8.48971C66.7963 9.16895 67.4755 9.73499 67.4755 10.6406C67.4755 11.7727 66.6831 12.3388 65.6642 12.3388C64.1925 12.3388 63.2869 11.0935 62.1548 8.71613H61.8152L61.9284 11.9991C62.6076 12.3388 64.1925 12.7916 65.7774 12.7916C68.268 12.7916 70.7585 11.3199 70.7585 8.82934C70.8717 7.58406 69.9661 6.79161 68.4944 5.77274Z"
            fill="#437089"
        />
        <path
            d="M81.3941 11.5399C81.2809 11.5399 81.1677 11.5399 81.0545 11.5399C80.3752 11.5399 79.8092 10.9738 79.8092 10.1814V10.0682C79.8092 8.48329 81.1677 8.25687 81.3941 7.35121V11.5399ZM85.8091 11.7663C85.5827 11.8795 85.3563 11.9927 85.2431 11.9927C84.9035 11.9927 84.6771 11.6531 84.6771 11.3135C84.6771 8.25687 84.6771 6.21914 84.6771 6.21914C84.6771 4.63424 83.3186 3.8418 81.1677 3.8418C78.9035 3.8418 76.979 4.74745 76.979 6.10594C76.979 6.89839 77.6582 7.57763 78.4507 7.57763C79.3563 7.57763 80.0356 6.89839 80.0356 6.10594C80.0356 5.31349 79.3564 4.63425 78.5639 4.63425C78.7903 4.52104 79.13 4.52104 79.3564 4.52104C80.4884 4.52104 81.3941 5.20028 81.3941 6.33235V6.44556C81.3941 7.46442 80.8281 7.91725 79.4696 8.37008C77.7715 8.82291 76.6394 9.38894 76.6394 10.9738C76.6394 12.3323 77.6582 13.238 79.0167 13.238C80.1488 13.238 80.828 12.6719 81.2809 11.9927C81.3941 12.7852 81.9601 13.238 83.0922 13.238C84.2243 13.238 85.1299 12.7852 85.8091 12.1059V11.7663Z"
            fill="#437089"
        />
    </svg>
);

export default EveningStandardLogo;
