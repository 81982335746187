import React, { useContext } from 'react';
import Img from 'gatsby-image';
import { BasicSquare, WedgedArch } from 'aphrodite-shared/SVGComponents/shapes';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import styles from './InfoAndImage.module.scss';
import ReactMarkdown from 'react-markdown/with-html';
import Quote from './Quote';
import { PrimaryCta } from '../components/PrimaryCta/PrimaryCta';

const InfoAndImage = ({ title, textBlock, primaryImg }) => {
    const context = useContext(IwContext);
    function handleOpenStepZero() {
        context.openStepZero();
    }

    return (
        <div className={styles.NewInfoAndImage}>
            <div className={styles.LeftPane}>
                <div className={styles.Image}>
                    <WedgedArch className={styles.WedgedArch} color="core-65" />
                    <div className={styles.quoteText}>
                        <Quote
                            className={styles.Quote}
                            width="31"
                            height="24"
                        />
                        <p>
                            With iwoca's reasonable rates and amazing staff, we
                            were able to quickly obtain a loan to purchase
                            equipment before our grand opening
                        </p>
                        <span>Cecilia Downer</span>
                    </div>
                    <Img fluid={primaryImg.fluid} className={styles.coffee} />
                    <BasicSquare
                        className={styles.BasicDoughnut}
                        color="primary-60"
                    />
                </div>
            </div>
            <div className={styles.RightPane}>
                <div className={styles.TextContainer}>
                    <h2>{title}</h2>
                    <div className={styles.TextBlock}>
                        <ReactMarkdown source={textBlock} escapeHtml={false} />
                    </div>
                    <PrimaryCta
                        buttonClass={styles.eligibilityButton}
                        onClick={() => {
                            handleOpenStepZero();
                        }}
                        colour="secondary">
                        Get Started
                    </PrimaryCta>
                    <p className={styles.smallText}>
                        Dont worry, it won't affect your credit score.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InfoAndImage;
